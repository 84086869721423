<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <b-tbody>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                      <b-th style="width: 15%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                      <b-td style="width: 20%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('elearning_config.office_type') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</b-td>
                      <b-th style="width: 15%" >{{ $t('globalTrans.office') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('elearning_tpm.vanue') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.venue_bn : formData.venue }}</b-td>
                      <b-th style="width: 15%" >{{ $t('elearning_venue.total_capacity') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $n(formData.total_capacity) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('elearning_venue.rent_amount') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $n(formData.rent_amount) }}</b-td>
                      <b-th style="width: 15%" >{{ $t('elearning_config.contract_person_no') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">
                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.contact_person_no, { useGrouping: false }) }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.address_bn : formData.address }}</b-td>
                      <b-th style="width: 15%" >{{ $t('globalTrans.reason') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.reason_bn : formData.reason }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('globalTrans.start_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ formData.start_date | dateFormat }}</b-td>
                      <b-th style="width: 15%" >{{ $t('globalTrans.end_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ formData.end_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('globalTrans.start_time') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">
                        <slot v-if="formData.start_time">
                          {{ formData.start_time | time12Formate }}
                        </slot>
                      </b-td>
                      <b-th style="width: 15%" >{{ $t('globalTrans.end_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">
                        <slot v-if="formData.end_time">
                          {{ formData.end_time | time12Formate }}
                        </slot>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th colspan="6" class="text-center text-primary">{{$t('elearning_venue.reservation_info')}}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('globalTrans.name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</b-td>
                      <b-th style="width: 15%" >{{ $t('globalTrans.mobile') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.mobile, { useGrouping: false }) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%" >{{ $t('globalTrans.email') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ formData.email }}</b-td>
                      <b-th style="width: 15%" >{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">
                        <slot v-if="formData.reservation_org_id === 999">
                            {{formData.reservation_org_name}}
                        </slot>
                        <slot v-else>
                            {{ ($i18n.locale==='bn') ? formData.reservation_org_bn : formData.reservation_org }}
                        </slot>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 20%"  >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ $i18n.locale === 'bn' ? formData.reservation_address_bn : formData.reservation_address }}</b-td>
                      <b-th style="width: 20%"  >{{ $t('elearning_venue.total_participent') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 20%">{{ formData.total_participent }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { trainingElearningServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
